import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"

// import NewsletterSignUp from "../components/NewsletterSignup"
// import UnderDevelopment from "../components/UnderDevelopment"

const H2 = ({ children }) => (
  <h2 className="text-5xl text-center">{children}</h2>
)

const Media = ({ data }) => {
  const { allSanityAlbums } = data

  return (
    <Layout className="mx-10 text-center">
      <SEO title="Media" />
      <section className=" grid text-primary-dark">
        <Hero englishTitle={"Media"} syllabics={"kqxla zf56qfdD"}></Hero>
        {/* List or grid of albums */}
        <h2 className="text-5xl text-center mt-10">Photo Albums</h2>

        <div className="flex gap-x-10 gap-y-10 justify-center flex-wrap mt-10 mb-20 ">
          {(allSanityAlbums.edges.length > 0 &&
            allSanityAlbums.edges.map(({ node: album }) => {
              {
                /* Use  albumCover if provided in CMS, or use first image in array as cover */
              }
              const albumCover = album.albumCover
                ? album.albumCover.asset.fluid
                : album.albumImages[0].asset.fluid

              {
                /* Add opaque box shadow https://tailwindcss.com/docs/box-shadow */
              }

              {
                /* tailwind hover transition adapted from Tailwind docs: https://tailwindcss.com/docs/transition-property  */
              }

              return (
                <Link
                  className=" grid items-center w-64  transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                  to={`/albums/${album.slug.current}`}
                  title={`${album.title} album`}
                >
                  <h3 className="text-center pb-0 ">{album.title}</h3>
                  <Img
                    fluid={{
                      ...albumCover,
                      aspectRatio: 1 / 1,
                    }}
                    alt={album.title}
                    className="shadow-extra"
                  />
                </Link>
              )
            })) || (
            <div className="text-center text-4xl leading-normal mx-5">
              Sorry, no albums yet. Please check again soon!
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MediaPageQuery {
    allSanityAlbums {
      edges {
        node {
          title
          slug {
            current
          }
          albumImages {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          albumCover {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Media
